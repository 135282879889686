
import {
  CalendarEventDetailsView,
  ConfirmDialog,
  useCalendarEventActions,
  UserType,
} from '@bd/components'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    CalendarEventDetailsView,
    ConfirmDialog,
  },
  setup() {
    return {
      UserType,
      ...useCalendarEventActions(),
    }
  },
})
