<template>
  <CalendarEventDetailsView
    :eventDetails="eventDetails"
    :userType="UserType.Agent"
    @eventAccept="onEventAccept"
    @eventEdit="onEventEdit"
    @eventCancel="onEventCancel"
    @eventReject="onEventReject"
  />
  <ConfirmDialog
    v-if="confirmationDialogState && confirmationDialogState.options"
    v-model:visible="confirmationDialogState.visible"
    :title="confirmationDialogState.options.title"
    :description="confirmationDialogState.options.description"
    :submitPending="submitPending"
    @confirm="onDialogConfirm"
    @reject="onDialogReject"
  />
</template>

<script lang="ts">
import {
  CalendarEventDetailsView,
  ConfirmDialog,
  useCalendarEventActions,
  UserType,
} from '@bd/components'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    CalendarEventDetailsView,
    ConfirmDialog,
  },
  setup() {
    return {
      UserType,
      ...useCalendarEventActions(),
    }
  },
})
</script>

<style lang="scss" scoped></style>
